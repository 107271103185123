import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { APLightbox, APResetPwd } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TeacherModel } from "@/models";
import { TeCommon } from "../Common";

export class InfoForm {
    acc = '';
    name = '';
    phone = '';
    school = '';
    pic = ''
    tid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherIndexController>({
    components: {
        Header,Footer, APLightbox, APResetPwd
    }
})
export default class ApTeacherIndexController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private infoForm = new InfoForm();
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        const item: { [key: string]: string } = {
            tid: this.tid,
            token: this.token,
        }
        const editData = await TeacherModel.teGet(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            tid: string;
            acc: string;
            name: string;
            phone: string;
            pic: string;
            school: string;
        };
        this.infoForm.tid = item.tid;
        this.infoForm.acc = item.acc;
        this.infoForm.name = item.name;
        this.infoForm.phone = item.phone;
        this.infoForm.pic = item.pic;
        this.infoForm.school = item.school;
    }

    public async openResetPwdLB() {
        this.$emit("openResetPwd", true, this.infoForm.acc);
    }

    public async validateAndSubmitPwd(newPwd: string) {
        const item: { [key: string]: string } = {
            tid: this.tid,
            pwd: newPwd,
            token: this.token,
        }
        const errNo = await TeacherModel.teEditPwd(item);
        switch (errNo) {
            case ErrorCode.InvalidToken:
                TeCommon.logout();
                break;
            case ErrorCode.Success:
                this.doubleClick = false;
                this.$router.go(0); //reload page
                break;
            default:
                this.lightbox.errorMsg = ErrorMessage[errNo];
                this.lightbox.showLB = true;
                this.doubleClick = false;
                break;
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}